
/**  @author Daniele */

import $ from 'jquery';
import { TweenMax } from 'gsap';



class Composition{

	constructor(opt){
		let self = this;

		self._name		= '';
		self._scene 	= opt.scene;
		self._camera	= opt.camera;
		self._renderer	= opt.renderer;
		self._defaultCameraPosition = { x: 0, y: 0, z: 100 };
		self._newCameraPosition = self._defaultCameraPosition;
		self._newCameraLookAt	= { x: 0, y: 0, z: 0 }

		self._zenoneSpeed	=  0.12;
		self._defaultZenoneSpeed = self._zenoneSpeed;

		self._renderable	= false;
		self._camerable		= false;

		self._doConstructor();
	}
	_doConstructor(){
		let self = this;
	}
	play(p_data){
		let self = this;
		self._renderable 	= true;
		self._camerable		= true;
		self._zenoneSpeed 	= self._defaultZenoneSpeed;

		self._doPlay(p_data);
	}
	_doPlay(p_data){
		let self = this;
	}
	pause(){
		let self = this;

		self._doPause();
		setTimeout(function(){ self._onPaused(); }, 500);
	}
	_doPause(){
		let self = this;

	}
	render(){
		let self = this;
		self._doRender();
		self._updateCamera();
	}
	_doRender(){
		let self = this;

	}

	_onPaused(){
		let self = this;

		self._camerable	= false;
		$('body').trigger('composition-paused');

		self._close();
	}
	_close(){
		let self = this;

		setTimeout(function(){ self._onClosed(); }, 1000);
	}
	_onClosed(){
		let self = this;

		self._renderable = false;
	}



	_updateCamera(){
		let self = this;

		if(self._camerable){

			self._doUpdateCamera();

			self._camera.position.x = self._zenoneValue(self._camera.position.x, self._newCameraPosition.x);
			self._camera.position.y = self._zenoneValue(self._camera.position.y, self._newCameraPosition.y);
			self._camera.position.z = self._zenoneValue(self._camera.position.z, self._newCameraPosition.z);

			self._camera.lookAt(self._newCameraLookAt);
		}

		//setTimeout(function(){ window.requestAnimationFrame(function(){ self._updateZenoneSpeed(); }); }, 1000);
	}
	_doUpdateCamera(){
		let self = this;

		self._newCameraPosition = self._defaultCameraPosition;
		self._newCameraLookAt = self._scene.position;
	}
	_zenoneValue(p_actualValue, p_newValue){
		let self = this;

		let value = self._zenoneSpeed < 1 ? p_actualValue + (p_newValue - p_actualValue) * self._zenoneSpeed : p_newValue;
		return value;
	}
	_updateZenoneSpeed(){
		let self = this;

		self._zenoneSpeed += 0.01;
	}




}


export default Composition;

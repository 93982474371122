
/**  @author Daniele */

import $ from 'jquery';
import { TweenMax } from 'gsap';

import Composition from '../composition';

const THREE = require('three');
window.THREE = THREE;
require('../../utils/GeometryUtilsMod');


class Video extends Composition{

	_doConstructor(opt){
		let self = this;

		self._name = 'VIDEO';
		self._time = 0;
		self._video = null;
		self._animationTime = { value: 0.0 };
		self._openingTimeout = null;
		self._shaderMaterial = new THREE.ShaderMaterial({
			depthWrite: false,
			transparent: true,
			vertexColors: THREE.VertexColors,
			uniforms: { size: { value: 2.5 }, opacity: { value: 0.0 }, },
			vertexShader: document.getElementById( 'vertexShaderBase' ).textContent,
			fragmentShader: document.getElementById( 'fragmentShaderBase' ).textContent,
		});

		//self._createVideo();
		self._createObject();
	}
	_doPlay(p_data){
		let self = this;

		if(App.AUDIO_CONTEXT.state == 'running'){
			self._loadAudio();
			self._audioElement.currentTime = 0;
			self._isPlaying = true;
			self._audioElement.play();
		}


		//TweenMax.killTweensOf(self._video.material);
		//TweenMax.to(self._video.material, .4, { opacity: 1,  ease: 'circ.inOut', delay: .3 });
		TweenMax.killTweensOf(self._shaderMaterial.uniforms.opacity);


		TweenMax.to(self._shaderMaterial.uniforms.opacity, .4, { value: 0.8,  ease: 'circ.inOut', delay: .3 });
	}
	// override
	pause(){
		let self = this;

		self._doPause();
		setTimeout(function(){ self._onPaused(); }, 500);
	}
	_doPause(){
		let self = this;

		if(self._openingTimeout) clearTimeout(self._openingTimeout);

		/*setTimeout(function(){
			self._videoElement.pause();
			self._videoElement.currentTime = 0;
		}, 2000);*/
		//TweenMax.killTweensOf(self._video.material);
		TweenMax.killTweensOf(self._shaderMaterial.uniforms.opacity);

		//TweenMax.to(self._video.material, .5, { opacity: 0,  ease: 'circ.inOut' });

		if(self._isPlaying){
			TweenMax.to(self._shaderMaterial.uniforms.opacity, .5, { value: 0,  ease: 'circ.inOut'});
			self._audioElement.pause();
		}
	}
	_doRender(){
		let self = this;
		self._render();
	}



	_changeVideo(p_id){
		let self = this;

		switch(p_id){
			case 'home':
				self._videoElement = document.getElementById('main-video-home');
				break;
			case 'flow':
				self._videoElement = document.getElementById('main-video-flow');
				break;
			case 'discovery':
				self._videoElement = document.getElementById('main-video-discovery');
				break;
			case 'knowledge':
				self._videoElement = document.getElementById('main-video-knowledge');
				break;
		}
		self._videoElement.currentTime = 0;
		self._texture = new THREE.VideoTexture(self._videoElement);
		self._video.material.map = self._texture;

	}


	_createVideo(){
		let self = this;

		self._videoElement = document.getElementById('main-video-home');
		self._videoElement.currentTime = 0;

		let geometry = new THREE.PlaneGeometry( 822, 350, 10, 5 );
		for(let i = 0; i < geometry.vertices.length; i++) {
			geometry.vertices[i].z = geometry.vertices[i].z + Math.random() * 20 - 10;
			geometry.vertices[i].base = geometry.vertices[i].z;
			geometry.vertices[i].speed = Math.random() * 0.1;
			geometry.vertices[i].time = Math.random() * 2 - 1;
		}

		self._texture = new THREE.VideoTexture(self._videoElement);
		let videoMaterial = new THREE.MeshBasicMaterial({ map: self._texture, transparent: true });
		self._video = new THREE.Mesh(geometry, videoMaterial);
		self._video.material.transparent = true;
		self._video.material.opacity = 0;
		self._video.position.x = 0;
		self._video.position.y = 0;
		self._video.position.z = -70;
		self._video.scale.x = 0.45;
		self._video.scale.y = 0.45;
		self._video.scale.z = 0.45;

		self._scene.add(self._video);
	}
	_deformVideo(){
		let self = this;

		for(let i = 0; i < self._video.geometry.vertices.length; i++) {
			self._video.geometry.vertices[i].time += self._video.geometry.vertices[i].speed;
			self._video.geometry.vertices[i].z = self._video.geometry.vertices[i].base + Math.cos(self._video.geometry.vertices[i].time) * 20;
		}

		self._video.geometry.computeVertexNormals();
		self._video.geometry.normalsNeedUpdate = true;
		self._video.geometry.verticesNeedUpdate = true;
	}

	_loadAudio(){
		let self = this;

		if(self._audioInitialized) return false;

		self._audioInitialized = true;
		//self._audioElement = new Audio($('#polmone-audio').attr('src'));
		self._audioElement = document.getElementById('flow-audio');
		self._audioElement.loop = true;
	}
	_createObject(){
		let self = this;

		let tunnelGeometry = new THREE.TorusKnotGeometry(300, 1, 500, 100, 1, 1);
		self._generateParticles(tunnelGeometry);

	}
	_generateParticles(p_tunnelGeometry){
		let self = this;

		let particlesGeometry = p_tunnelGeometry;

		for (let i = 0; i < particlesGeometry.vertices.length; i++) {
			particlesGeometry.vertices[i] = {
				x: particlesGeometry.vertices[i].x + Math.random() * 50 - 25,
				y: particlesGeometry.vertices[i].y + Math.random() * 100 - 50,
				z: particlesGeometry.vertices[i].z + Math.random() * 80 - 40,
			};
		}

		let torus1 = new THREE.Points(particlesGeometry, self._shaderMaterial);
		torus1.rotation.x = 1.15;
		torus1.rotation.z = -Math.PI * 0.08;

		let torus2 = torus1.clone();
		let torus3 = torus1.clone();

		self._particles1 = new THREE.Group();
		self._particles1.add(torus1);

		self._particles2 = new THREE.Group();
		self._particles2.rotation.y = Math.PI * 0.25;
		self._particles2.add(torus2);

		self._particles3 = new THREE.Group();
		self._particles3.rotation.y = Math.PI * 0.5;
		self._particles3.add(torus3);

		self._scene.add(self._particles1);
		self._scene.add(self._particles2);
		//self._scene.add(self._particles3);

	}






	_render(){
		let self = this;
		if(self._renderable) self._animate();
	}
	_animate(){
		let self = this;

		//self._deformVideo();

		self._particles1.rotation.y += 0.02;
		self._particles2.rotation.y += 0.015;
		self._particles3.rotation.y += 0.01;

	}

}


export default Video;

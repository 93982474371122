
/**  @author Daniele */

import $ from 'jquery';
import { TweenMax } from 'gsap';
import Composition from '../composition';

const THREE = require('three');
window.THREE = THREE;
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { BloomPass } from 'three/examples/jsm/postprocessing/BloomPass.js';
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass.js';
import { FocusShader } from 'three/examples/jsm/shaders/FocusShader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';


class People extends Composition{

	_doConstructor(){
		let self = this;

		self._name			= 'PEOPLE';
		self._renderer.autoClear = false;

		self._time 		= 0;
		self._renderable= false;
		self._camerable	= false;
		self._canManageOpacity = false;
		self._clock = new THREE.Clock();

		self._meshes 		= new Array();
		self._clonemeshes 	= new Array();
		self._newCameraPos	= { x: 0, y: 0, z: 0 };

		self._shaderMaterial = new THREE.ShaderMaterial({
			depthWrite: false,
			transparent: true,
			vertexColors: THREE.VertexColors,
			uniforms: { size: { value: .5 }, opacity: { value: 0.0 }, },
			vertexShader: document.getElementById( 'vertexShaderBase' ).textContent,
			fragmentShader: document.getElementById( 'fragmentShaderBase' ).textContent,
		});


		self._generatePeople();
	}
	_doPlay(){
		let self = this;

		TweenMax.to(self._shaderMaterial.uniforms.opacity, 0.3, { value: 1, ease: 'none' });
	}
	_doPause(){
		let self = this;
		self._canManageOpacity = false;
		TweenMax.to(self._shaderMaterial.uniforms.opacity, 0.3, { value: 0, ease: 'none' });
	}
	_doRender(){
		let self = this;
		self._render();
	}




	_generatePeople(){
		let self = this;

		self._parent = new THREE.Object3D();
		self._scene.add(self._parent);

		var positions = self._combineBuffer( App.MODEL_male.model, 'position' );
		self._createMesh( positions, self._scene, 4, -500, -350, 600, 0x4d6ae8 );
		self._createMesh( positions, self._scene, 4, 500, -350, 0, 0x4d6ae8 );
		self._createMesh( positions, self._scene, 4, -250, -350, 1500, 0x4d6ae8 );
		self._createMesh( positions, self._scene, 4, -250, -350, -1500, 0x4d6ae8 );


		var positions = self._combineBuffer( App.MODEL_female.model, 'position' );
		self._createMesh( positions, self._scene, 4, -1000, -350, 0, 0x4d6ae8 );
		self._createMesh( positions, self._scene, 4, 0, -350, 0, 0x4d6ae8 );
		self._createMesh( positions, self._scene, 4, 1000, -350, 400, 0x4d6ae8 );
		self._createMesh( positions, self._scene, 4, 250, -350, 1500, 0x4d6ae8 );
		self._createMesh( positions, self._scene, 4, 250, -350, 2500, 0x4d6ae8 );



		var grid = new THREE.Points(new THREE.PlaneBufferGeometry(15000, 15000, 64, 64), self._shaderMaterial);
		grid.position.y = -400;
		grid.rotation.x = -Math.PI / 2;
		self._parent.add( grid );

		self._parent.position.x = -5;
		self._parent.position.y = -5;
		self._parent.position.z = 30;
		self._parent.rotation.x = Math.PI / 8;
		self._parent.scale.x = 0.03;
		self._parent.scale.y = 0.03;
		self._parent.scale.z = 0.03;

		// postprocessing
		/*
		var renderModel = new RenderPass(self._scene, self._camera);
		var effectBloom = new BloomPass(0.75);
		var effectFilm = new FilmPass(0.5, 0.5, 1448, false);

		self._effectFocus = new ShaderPass(FocusShader);

		self._effectFocus.uniforms["screenWidth"].value = window.innerWidth * window.devicePixelRatio;
		self._effectFocus.uniforms["screenHeight"].value = window.innerHeight * window.devicePixelRatio;

		self._composer = new EffectComposer(self._renderer);
		self._composer.addPass(renderModel);
		self._composer.addPass(effectBloom);
		self._composer.addPass(effectFilm);
		self._composer.addPass(self._effectFocus);
		*/
	}

	_combineBuffer( model, bufferName ) {
		let self = this;

		let count = 0;
		model.traverse( function ( child ) {
			if ( child.isMesh ) {
				var buffer = child.geometry.attributes[ bufferName ];
				count += buffer.array.length;
			}
		});

		var combined = new Float32Array( count );
		let offset = 0;
		model.traverse( function ( child ) {
			if ( child.isMesh ) {
				var buffer = child.geometry.attributes[ bufferName ];
				combined.set( buffer.array, offset );
				offset += buffer.array.length;
			}
		});

		return new THREE.BufferAttribute( combined, 3 );
	}


	_createMesh( positions, scene, scale, x, y, z, color ) {
		let self = this;

		var geometry = new THREE.BufferGeometry();
		geometry.setAttribute( 'position', positions.clone() );
		geometry.setAttribute( 'initialPosition', positions.clone() );

		geometry.attributes.position.setUsage( THREE.DynamicDrawUsage );

		var clones = [
			//[ 6000, 0, - 4000 ],
			//[ 5000, 0, 0 ],
			//[ 1000, 0, 5000 ],
			//[ 1000, 0, - 5000 ],
			//[ 4000, 0, 2000 ],
			//[ - 4000, 0, 1000 ],
			//[ - 5000, 0, - 5000 ],
			[ 0, 0, 0 ]
		];

		let mesh;
		for ( var i = 0; i < clones.length; i ++ ) {

			//var c = ( i < clones.length - 1 ) ? 0x252525 : color;
			var c = color;
			//mesh = new THREE.Points( geometry, new THREE.PointsMaterial( { size: 0.2, color: c } ) );

			mesh = new THREE.Points(geometry, self._shaderMaterial);
			mesh.scale.x = mesh.scale.y = mesh.scale.z = scale;
			mesh.position.x = x + clones[ i ][ 0 ];
			mesh.position.y = y + clones[ i ][ 1 ];
			mesh.position.z = z + clones[ i ][ 2 ];
			self._parent.add( mesh );
			self._clonemeshes.push({
				mesh: mesh,
				//speed: 0.5 + Math.random(),
				verticesDown: 0,
				verticesUp: 0,
				direction: 0,
				speed: 0.5 + Math.random(),
				delay: Math.floor( 200 + 200 * Math.random() ),
				start: Math.floor( 100 + 200 * Math.random() ),
			});
		}

		self._meshes.push({
			mesh: mesh,
			verticesDown: 0,
			verticesUp: 0,
			direction: 0,
			speed: 15,
			delay: Math.floor( 200 + 200 * Math.random() ),
			start: Math.floor( 100 + 200 * Math.random() ),
		});

	}



	_render(){
		let self = this;
		if(self._renderable) self._animate();
	}
	_animate(){
		let self = this;

		var delta = 10 * self._clock.getDelta();
		delta = delta < 2 ? delta : 2;
		self._parent.rotation.y += - 0.02 * delta;

		for(var j = 0; j < self._clonemeshes.length; j++) {
			var cm = self._clonemeshes[j];
			cm.mesh.rotation.y += - 0.1 * delta * cm.speed;
		}

		for(var j = 0; j < self._meshes.length; j++) {
			var data = self._meshes[j];
			var positions = data.mesh.geometry.attributes.position;
			var initialPositions = data.mesh.geometry.attributes.initialPosition;
			var opacity = data.mesh.material.uniforms.opacity.value;
			var count = positions.count;
			if (data.start > 0) {
				data.start -= 1;
			} else {
				if(data.direction === 0) data.direction = - 1;
			}

			for ( var i = 0; i < count; i ++ ) {
				var px = positions.getX( i );
				var py = positions.getY( i );
				var pz = positions.getZ( i );

				// falling down
				if ( data.direction < 0 ) {
					if ( py > 0 ) {
						positions.setXYZ(
							i,
							px + 1.5 * ( 0.50 - Math.random() ) * data.speed * delta,
							py + 3.0 * ( 0.25 - Math.random() ) * data.speed * delta,
							pz + 1.5 * ( 0.50 - Math.random() ) * data.speed * delta
						);
					} else {
						data.verticesDown += 1;
					}
				}

				// rising up
				if ( data.direction > 0 ) {
					var ix = initialPositions.getX( i );
					var iy = initialPositions.getY( i );
					var iz = initialPositions.getZ( i );
					var dx = Math.abs( px - ix );
					var dy = Math.abs( py - iy );
					var dz = Math.abs( pz - iz );
					var d = dx + dy + dx;
					if ( d > 1 ) {
						positions.setXYZ(
							i,
							px - ( px - ix ) / dx * data.speed * delta * ( 0.85 - Math.random() ),
							py - ( py - iy ) / dy * data.speed * delta * ( 1 + Math.random() ),
							pz - ( pz - iz ) / dz * data.speed * delta * ( 0.85 - Math.random() )
						);
					} else {
						data.verticesUp += 1;
					}
				}
			}

			// all vertices down
			if ( data.verticesDown >= count ) {
				if ( data.delay <= 0 ) {
					data.direction = 1;
					data.speed = 5;
					data.verticesDown = 0;
					data.delay = 320;
				} else {
					data.delay -= 1;
				}
			}

			// all vertices up
			if ( data.verticesUp >= count ) {
				if ( data.delay <= 0 ) {
					data.direction = - 1;
					data.speed = 15;
					data.verticesUp = 0;
					data.delay = 120;
				} else {
					data.delay -= 1;
				}
			}
			positions.needsUpdate = true;

		}

		//self._composer.render( 0.01 );

	}

}


export default People;


import { TweenMax } from 'gsap';

const THREE = require('three');
window.THREE = THREE;
require('../../utils/GeometryUtilsMod');


class MorphingLoader{

	constructor(opt){
		let self = this;

		self._nVerts 		= 30000;
		self._shape 		= opt.shape;
		self._customShape 	= opt.customShape || null;


		self._init();
	}
	compose(){
		let self = this;
		self._status = 'compose';
	}
	destroy(){
		let self = this;
		self._status = 'destroy';
	}
	getObject(){
		let self = this;
	}



	_init(){
		let self = this;

		self._generateObject();
	}


	_generateObject(){
		let self = this;

		let objectGeometry = null;

		switch(self._shape) {
			case 'sphere':
				objectGeometry = new THREE.SphereBufferGeometry( 10, 32, 32 );
				break;
			case 'torus':
				objectGeometry = new THREE.TorusBufferGeometry( 10, 5, 32, 128 );
				break;
			case 'torusKnot':
				objectGeometry = new THREE.TorusKnotBufferGeometry( 10, 3, 300, 128 );
				break;
			case 'explosion':
				objectGeometry = new THREE.BoxBufferGeometry( 20, 20, 20, 300, 300, 300 );
				break;
			case 'canoa':
			case 'cammelli':
			case 'flamingo':
			case 'volti':
			case 'mano':
			case 'airbus':
			case 'leonardo':
				objectGeometry = self._customShape;
				break;
		}


		let object = null;
		if(self._shape == 'explosion'){
			let objectVerts = THREE.GeometryUtilsMod.randomPointsInBufferGeometry(objectGeometry, self._nVerts);
			object = new Float32Array( objectVerts.length * 3 );
			for(let v = 0; v < objectVerts.length; v += 1){
				object[ v * 3 + 0 ] = objectVerts[v].x + Math.random() * 40 - 20;
				object[ v * 3 + 1 ] = objectVerts[v].y + Math.random() * 40 - 20;
				object[ v * 3 + 2 ] = objectVerts[v].z + Math.random() * 40 - 20;
			}
		} else {
			let objectVerts = THREE.GeometryUtilsMod.randomPointsInBufferGeometry(objectGeometry, self._nVerts);
			object = new Float32Array( objectVerts.length * 3 );
			for(let v = 0; v < objectVerts.length; v += 1){
				object[ v * 3 + 0 ] = objectVerts[v].x;
				object[ v * 3 + 1 ] = objectVerts[v].y;
				object[ v * 3 + 2 ] = objectVerts[v].z;
			}
		}

		let oscillazioneVerts = THREE.GeometryUtilsMod.randomPointsInBufferGeometry(objectGeometry, self._nVerts);
		let oscillazione = new Float32Array(oscillazioneVerts.length * 3 );
		for(let v = 0; v < oscillazioneVerts.length; v += 1){
			oscillazione[ v * 3 + 0 ] = Math.random() - 0.5;
			oscillazione[ v * 3 + 1 ] = Math.random() - 0.5;
			oscillazione[ v * 3 + 2 ] = Math.random() - 0.5;
		}



		self._object = object;
		self._oscillazione = oscillazione;


	}


}


export default MorphingLoader;

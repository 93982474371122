
/**  @author Daniele */

import $ from 'jquery';
import { TweenMax } from 'gsap';

import Composition from '../composition';

const THREE = require('three');
window.THREE = THREE;
require('../../utils/GeometryUtilsMod');


class Polmone extends Composition{

	_doConstructor(opt){
		let self = this;

		self._name		= 'POLMONE';
		self._analyser	= false;
		self._heartObj 	= false;
		self._audioInitialized = false;

		self._time = 0;

		self._shaderMaterial = new THREE.ShaderMaterial({
			depthWrite: false,
			transparent: true,
			vertexColors: THREE.VertexColors,
			uniforms: { size: { value: 1.0 }, opacity: { value: 0.0 }, movement: { value: 1.0 }, size: { value: 3.0 }},
			vertexShader: document.getElementById('vertexShaderTransition').textContent,
			fragmentShader: document.getElementById('fragmentShaderBase').textContent,
		});

		self._defineObjects();
	}
	_doPlay(){
		let self = this;


		if(App.AUDIO_CONTEXT.state == 'running'){
			self._loadAudio();
			self._audioElement.currentTime = 0;
			self._isPlaying = true;
			self._audioElement.play();
		}

		TweenMax.to(self._shaderMaterial.uniforms.opacity, 0.3, { value: 1, ease: 'none' });

	}
	_doPause(){
		let self = this;

		if(self._isPlaying){
			TweenMax.to(self._shaderMaterial.uniforms.opacity, 0.3, { value: 0, ease: 'none' });
			self._audioElement.pause();
		}
	}
	_doRender(){
		let self = this;
		self._render();
	}






	_loadAudio(){
		let self = this;


		if(self._audioInitialized) return false;

		self._audioInitialized = true;
		var listener = new THREE.AudioListener();
		var audio = new THREE.Audio(listener);

		//self._audioElement = new Audio($('#polmone-audio').attr('src'));
		self._audioElement = document.getElementById('polmone-audio');
		self._audioElement.loop = true;

		audio.setMediaElementSource(self._audioElement);
		self._analyser = new THREE.AudioAnalyser(audio, 128);
	}
	_defineObjects(){
		let self = this;

		self._polmoneObj = App.MODEL_polmone.model.children[0].geometry;
		self._createParticles();

	}

	_createParticles(){
		let self = this;

		var nVerts = 30000;

		self._polmone 	= self._createGeometry(self._polmoneObj, nVerts);
		self._explosion = self._createExplosion(self._polmoneObj, nVerts);

		self._bufferGeometry = new THREE.BufferGeometry();
		self._bufferGeometry.setAttribute('position', new THREE.BufferAttribute(self._explosion, 3));
		self._bufferGeometry.setAttribute('morph1', new THREE.BufferAttribute(self._polmone, 3));

	    self._mesh = new THREE.Points(self._bufferGeometry, self._shaderMaterial);
		self._mesh.scale.x = 0.25;
		self._mesh.scale.y = 0.25;
		self._mesh.scale.z = 0.25;

		self._scene.add(self._mesh);
	}

	_createGeometry(objectGeometry, nVerts){
		let self = this;

		let objectVerts = THREE.GeometryUtilsMod.randomPointsInBufferGeometry(objectGeometry, nVerts);
		let object = new Float32Array(objectVerts.length * 3);
		for(let v = 0; v < objectVerts.length; v += 1){
			object[ v * 3 + 0 ] = objectVerts[v].x;
			object[ v * 3 + 1 ] = objectVerts[v].y;
			object[ v * 3 + 2 ] = objectVerts[v].z;
		}

		return object;
	}
	_createExplosion(objectGeometry, nVerts){
		let self = this;

		let objectVerts = THREE.GeometryUtilsMod.randomPointsInBufferGeometry(objectGeometry, nVerts);
		let object = new Float32Array(objectVerts.length * 3);
		for(let v = 0; v < objectVerts.length; v += 1){
			object[ v * 3 + 0 ] = objectVerts[v].x + Math.random() * 1000 - 500;
			object[ v * 3 + 1 ] = objectVerts[v].y + Math.random() * 1000 - 500;
			object[ v * 3 + 2 ] = objectVerts[v].z + Math.random() * 1000 - 500;
		}

		return object;
	}





	_render(){
		let self = this;
		if(self._renderable) self._animate();
	}
	_animate(){
		let self = this;

		self._mesh.rotation.y += 0.01;

		if(self._isPlaying && self._analyser){

			let newValue;
			var audioData = self._analyser.getFrequencyData();

			var perc = audioData[0] / 255;
			let value = perc + 0.3;
			if(value > 1) value = 1;
			newValue = self._shaderMaterial.uniforms.movement.value + (value - self._shaderMaterial.uniforms.movement.value) * 0.5 * 0.08;
			if(self._audioElement.played.length == 0) newValue = 1;

			self._shaderMaterial.uniforms.movement.value = newValue;
		}

		window.innerWidth <= 991 ? self._shaderMaterial.uniforms.size.value = 3.0 : self._shaderMaterial.uniforms.size.value = 2.0;
		window.innerWidth <= 991 ? self._mesh.position.z = -60 : self._mesh.position.z = 0;
	}

}


export default Polmone;


/**  @author Daniele */

import $ from 'jquery';
import { TweenMax } from 'gsap';

import Composition from '../composition';

const THREE = require('three');
window.THREE = THREE;
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
require('../../utils/GeometryUtilsMod');


class Heart extends Composition{

	_doConstructor(opt){
		let self = this;

		self._name		= 'HEART';
		self._analyser	= false;
		self._heartObj 	= false;
		self._movement	= 1.0;
		self._isPlaying	= false;
		self._audioInitialized = false;
		self._autoRotation = 0;
		self._position 	= { x: 20, y: 0, z: -15 };
		self._newPosition = self._position;
		self._audioContext = null;

		self._shaderMaterial = new THREE.ShaderMaterial({
			depthWrite: false,
			transparent: true,
			vertexColors: THREE.VertexColors,
			uniforms: { size: { value: .5 }, opacity: { value: 0.0 }, movement: { value: 1.0 }, size: { value: 3.0 }},
			vertexShader: document.getElementById('vertexShaderTransition').textContent,
			fragmentShader: document.getElementById('fragmentShaderBase').textContent,
		});

		self._defineObjects();
	}
	_doPlay(){
		let self = this;

		//self._audioElement.play();
		TweenMax.to(self._shaderMaterial.uniforms.opacity, 0.3, { value: 1, ease: 'none' });
	}
	_doPause(){
		let self = this;

		TweenMax.to(self._shaderMaterial.uniforms.opacity, 0.3, { value: 0, ease: 'none' });
		//self._audioElement.pause();
	}
	_doRender(){
		let self = this;
		self._render();
	}

	change(p_data){
		let self = this;

		if(p_data == 'START-PULSE') self._pulseHeartStart();
		if(p_data == 'STOP-PULSE') self._pulseHeartStop();
	}





	_loadAudio(){
		let self = this;


		if(self._audioInitialized) return false;

		self._audioInitialized = true;
		var listener = new THREE.AudioListener();
		var audio = new THREE.Audio(listener);

		//self._audioElement = new Audio($('#heart-audio').attr('src'));
		self._audioElement = document.getElementById('heart-audio');
		self._audioElement.loop = true;


		audio.setMediaElementSource(self._audioElement);
		self._analyser = new THREE.AudioAnalyser(audio, 128);
	}
	_defineObjects(){
		let self = this;

		self._heartObj = App.MODEL_heart.model.children[0].geometry;
		self._createParticles();

	}

	_createParticles(){
		let self = this;

		var nVerts = 30000;
		self._heart0 = self._createGeometry(self._heartObj, nVerts);
		self._heart1 = self._createGeometry(self._heartObj, nVerts);


		self._bufferGeometry = new THREE.BufferGeometry();
		self._bufferGeometry.setAttribute( 'position', new THREE.BufferAttribute( self._heart0, 3 ) );
		self._bufferGeometry.setAttribute( 'morph1', new THREE.BufferAttribute( self._heart1, 3 ) );


	    self._mesh = new THREE.Points(self._bufferGeometry, self._shaderMaterial);
		self._mesh.scale.x = 0.25;
		self._mesh.scale.y = 0.25;
		self._mesh.scale.z = 0.25;

		self._mesh.position.x = self._position.x;
		self._mesh.position.y = self._position.y;
		self._mesh.position.z = self._position.z;

		self._scene.add(self._mesh);
	}

	_createGeometry(objectGeometry, nVerts){
		let self = this;

		let objectVerts = THREE.GeometryUtilsMod.randomPointsInBufferGeometry(objectGeometry, nVerts);
		let object = new Float32Array(objectVerts.length * 3);
		for(let v = 0; v < objectVerts.length; v += 1){
			object[ v * 3 + 0 ] = objectVerts[v].x ;
			object[ v * 3 + 1 ] = objectVerts[v].y ;
			object[ v * 3 + 2 ] = objectVerts[v].z ;
		}

		return object;
	}

	_pulseHeartStart(){
		let self = this;

		if(App.AUDIO_CONTEXT.state == 'running'){
			self._isPlaying = true;
			self._loadAudio();
			self._audioElement.currentTime = 0;
			self._audioElement.play();
			self._newPosition = { x: 0, y: 0, z: 20 };
		}

	}
	_pulseHeartStop(){
		let self = this;

		if(self._isPlaying){
			self._isPlaying = false;
			self._audioElement.pause();
			self._newPosition = { x: 20, y: 0, z: -15 };
		}

	}




	_render(){
		let self = this;
		if(self._renderable) self._animate();
	}
	_animate(){
		let self = this;

		let newValue;
		if(self._isPlaying && self._analyser){
			let audioData = self._analyser.getFrequencyData();
			if(audioData[0] != 0){
				let perc = audioData[0] / 255;
				newValue = (audioData) ? 1 - (perc - 1) * - .2 : 1;
			} else {
				newValue = 1.0;
			}
		} else {
			newValue = 1.0;
			self._autoRotation += 0.01;
			if(self._autoRotation > Math.PI * 2) self._autoRotation = 0;
			self._mesh.rotation.y = self._autoRotation;
		}

		self._shaderMaterial.uniforms.movement.value =  newValue;

		if(Math.abs(self._newPosition.x - self._mesh.position.x) > 0.01) self._mesh.position.x = self._mesh.position.x + (self._newPosition.x - self._mesh.position.x) * .5 * .08;
		if(Math.abs(self._newPosition.y - self._mesh.position.y) > 0.01) self._mesh.position.y = self._mesh.position.y + (self._newPosition.y - self._mesh.position.y) * .5 * .08;
		if(Math.abs(self._newPosition.z - self._mesh.position.z) > 0.01) self._mesh.position.z = self._mesh.position.z + (self._newPosition.z - self._mesh.position.z) * .5 * .08;
	}

}


export default Heart;

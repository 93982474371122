(function($) {
	
	function cookiePolicyBannerEstesaOpen($container, url, onEstesaOpen, onEstesaClose, event) {
			$.ajax({
				url : url,
			}).done(function(data) {
				var $data = $(data);
				$container.append($data);
				onEstesaOpen($data);
				$data.find('.btn-close').on(event, function() {
					var $t = $(this);
					onEstesaClose($data);
				});
				
			});
	}

	var methods = {
		init : function(opts) {
			var opt = $.extend({}, $.fn.cookiePolicyBanner.defaults, opts);

			return this.each(function() {
				var $this = $(this);
				$this.find('.cookie-policy-ext-link').attr('href', opt.policyURL);
				if (!opt.bannerURL) {
					alert('INDICARE IL TPL');
				}
								
				$this.data('cookiePolicyBanner', opt);
				
				if(opt.auto) {
					$this.cookiePolicyBanner('open');
				}
				
			});
		},
		open: function() {

			return this.each(function() {
				var $this = $(this);
				var opt = $this.data('cookiePolicyBanner');
				$this.find('.cookie-policy-ext-link').attr('href', opt.policyURL);
				if (!opt.bannerURL) {
					alert('INDICARE IL TPL');
				}
				
				if (!$.cookie("cookie-privacy-accept") || $.cookie("cookie-privacy-accept") == 0) {
					$.ajax({
						url : opt.bannerURL,
					}).done(function(data) {
						var $data = $(data);
						if(!opt.isModal) {
							$data.find('.policy-link').attr('href', opt.policyURL);
						} else {
							$data.find('.policy-link').on(opt.event, function() {
								cookiePolicyBannerEstesaOpen($this, opt.policyURL, opt.onEstesaOpen, opt.onEstesaClose, opt.event);
								return false;
							});
						}
						$this.append($data);
						$data.find('.btn-accept').on(opt.event, function() {
							var $t = $(this);
							$data.addClass('hidden');
							$.cookie("cookie-privacy-accept", 1, {
								expires : 360,
								path : '/'
							});

						});
						$data.removeClass('hidden');
						$data.addClass('open');
					});

				}
				if(opt.isModal) {
					$('.policy-link').on(opt.event, function(e) {
						// APERTURA POLICY TPL
						cookiePolicyBannerEstesaOpen($this, opt.policyURL, opt.onEstesaOpen, opt.onEstesaClose, opt.event);
						return false;
					});
				}
			});
		}
	};

	$.fn.cookiePolicyBanner = function(method) {
		if (methods[method]) {
			return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
		} else if ( typeof method === 'object' || !method) {
			return methods.init.apply(this, arguments);
		} else {
			$.error('Method ' + method + ' does not exist on cookiePolicyBanner');
		}

	};
	$.fn.cookiePolicyBanner.defaults = {
		bannerURL : null,
		event: 'click',
		policyURL : 'cookie-policy',
		isModal: false,
		auto: true,
		onEstesaOpen: function($el) {},
		onEstesaClose: function($el) {
			$el.remove();
		}
	};
})(jQuery);


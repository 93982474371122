
/**  @author Daniele */

import $ from 'jquery';
import { TweenMax } from 'gsap';

import Composition from '../composition';

import * as THREE from 'three';
import SimplexNoise from '../../utils/simplex-noise';


class Terrain extends Composition{

	_doConstructor(){
		let self = this;

		self._name			= 'TERRAIN';
		self._opacity 		= 0;
		self._terrainSize 	= 100;

		self._shaderMaterial = new THREE.ShaderMaterial({
			depthWrite: false,
			transparent: true,
			vertexColors: THREE.VertexColors,
			uniforms: { size: { value: 0.5 }, opacity: { value: 0.0 }, movement: { value: 1.0 }, size: { value: 3.0 }},
			vertexShader: document.getElementById( 'vertexShaderBase' ).textContent,
			fragmentShader: document.getElementById( 'fragmentShaderBase' ).textContent,
		});


		self._generateTerrain();
	}
	_doPlay(){
		let self = this;
		TweenMax.to(self._shaderMaterial.uniforms.opacity, 0.3, { value: 1, ease: 'none' });
	}
	_doPause(){
		let self = this;

		TweenMax.to(self._shaderMaterial.uniforms.opacity, 0.3, { value: 0, ease: 'none' });
	}
	_doRender(){
		let self = this;
		self._render();
	}



	_generateTerrain(){
		let self = this;

		self._simplex = new SimplexNoise();

		let nPoints = 150;
		let size 	= self._terrainSize;
		let geometry = new THREE.PlaneGeometry(size, size, nPoints, nPoints);

		let realgeometry = new THREE.Geometry();

		geometry.verticesNeedUpdate = true;
		var newLength 	= geometry.vertices.length * 2;
		for (let i = 0; i < geometry.vertices.length; i++) {
			let v = geometry.vertices[i];
			v.x = v.x + Math.random() * 0.5 - 0.25;
			v.z = self._getNoise(v.x * 0.01, v.y * 0.01, v.z * 0.01, 0) * 3;
			v.z += self._getNoise(v.x * 0.03, v.y * 0.03, v.z * 0.03, 0) * 1.5;
			v.z += self._getNoise(v.x * 0.1, v.y * 0.125, v.z * 0.125, 0) * 0.75;
			v.z += self._getNoise(v.x * 0.1, v.y * 0.125, v.z * 0.125, 0) * 0.375;
			realgeometry.vertices[i] = new THREE.Vector3(v.x, v.y - size * 0.5, v.z);

			let row 	= Math.floor(i / (nPoints + 1)) + 1;
			let index 	= newLength - (row * (nPoints + 1)) + (i%(nPoints + 1));
			let incrementY 	= size - (size / (nPoints + 1)) * (row - 1) * 0.5;
			let newY 		= size * 0.5 - v.y + size * 0.5;
			realgeometry.vertices[index] = new THREE.Vector3(v.x, newY - size * 0.5, v.z);
		}

		self._mesh = new THREE.Points(realgeometry, self._shaderMaterial);
		self._mesh.rotation.x = -Math.PI / 2.05;
		self._mesh.rotation.z = 0.2;
		self._mesh.position.x = 10;
		self._mesh.position.y = -7;
		self._mesh.position.z = 100;

		self._scene.add(self._mesh);
	}

	_getNoise(x, y, z, t) {
		let self = this;

		return self._simplex.noise3D(x, y, z);
	}




	_render(){
		let self = this;
		if(self._renderable) self._animate();
	}
	_animate(){
		let self = this;

		for (let i = 0; i < self._mesh.geometry.vertices.length; i++) {
			self._mesh.geometry.vertices[i].y -= 0.2;
			if(self._mesh.geometry.vertices[i].y < -self._terrainSize) self._mesh.geometry.vertices[i].y = self._terrainSize;
		}

		self._mesh.geometry.verticesNeedUpdate = true;
		window.innerWidth <= 991 ? self._shaderMaterial.uniforms.size.value = 3.0 : self._shaderMaterial.uniforms.size.value = 1.5;

	}

}


export default Terrain;

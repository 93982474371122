
/**  @author Daniele */

import { TweenMax } from 'gsap';
import Composition from '../composition';
import MorphingLoader from './morphing-loader';

const THREE = require('three');
window.THREE = THREE;
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';


class Morphing1 extends Composition{

	_doConstructor(){
		let self = this;

		self._name		= 'MORPH-1';
		self._time		= 0;

		self._shaderMaterial	= null;
		self._autoMorph			= 0;
		self._direction			= 'forward';
		self._speed				= 0.003;
		self._newCameraPos		= { x: 0, y: 0, z: 0 };

		self._aMeshPos = [
			{ desktop: { x: 0, y: -2, z: 65 }, mobile: { x: 2, y: -2, z: 30 }},
			{ desktop: { x: 0, y: -11, z: 40 }, mobile: { x: 0, y: -12, z: 30 }}
		];
		self._aMeshRotation = [
			{ desktop: { x: 0, y: 0, z: 0 }, mobile: { x: 0, y: 0, z: 0 }},
			{ desktop: { x: 0, y: 0, z: Math.PI * 0.48 }, mobile: { x: 0, y: 0, z: Math.PI * 0.48 }}
		];

		if(window.innerWidth <= 991){
			self._newMeshPos 		= self._aMeshPos[0].mobile;
			self._newMeshRotation 	= self._aMeshRotation[0].mobile;
		} else {
			self._newMeshPos 		= self._aMeshPos[0].desktop;
			self._newMeshRotation 	= self._aMeshRotation[0].desktop;
		}

		self._actualModel		= 0;
		self._nextModel			= 0;

		self._shaderMaterial = new THREE.ShaderMaterial({
			depthWrite: false,
			transparent: true,
			vertexColors: THREE.VertexColors,
			uniforms: {
				size: { value: 2.0 },
				opacity: { value: 0.0 },
				movement: { value: 0.0 },
				oscillazione: { value: 0.0 },
				fromModel: { value: self._fromModel },
				toModel: { value: self._toModel },
			},
			vertexShader: document.getElementById('vertexShaderMorph').textContent,
	     	fragmentShader: document.getElementById('fragmentShaderBase').textContent
		});


		self._defineObjects();
	}
	_doPlay(){
		let self = this;
		TweenMax.to(self._shaderMaterial.uniforms.opacity, 0.3, { value: 1, ease: 'none' });
	}
	_doPause(){
		let self = this;
		TweenMax.to(self._shaderMaterial.uniforms.opacity, 0.3, { value: 0, ease: 'none' });
	}
	_doRender(){
		let self = this;
		self._render();
	}


	changeModel(p_model){
		let self = this;

		if(p_model == self._actualModel) return false;

		self._nextModel = p_model;
		self._shaderMaterial.uniforms.fromModel.value 	= self._actualModel;
		self._shaderMaterial.uniforms.toModel.value 	= self._nextModel;

		if(window.innerWidth <= 991){
			self._newMeshPos 		= self._aMeshPos[self._nextModel].mobile;
			self._newMeshRotation 	= self._aMeshRotation[self._nextModel].mobile;
		} else {
			self._newMeshPos 		= self._aMeshPos[self._nextModel].desktop;
			self._newMeshRotation 	= self._aMeshRotation[self._nextModel].desktop;
		}


		self._shaderMaterial.uniforms.movement.value = 0;
		TweenMax.to(self._shaderMaterial.uniforms.movement, 2, { value: 1, ease: 'circ.inOut' });
		self._actualModel = self._nextModel;
	}



	_defineObjects(){
		let self = this;

		self._voltiObj = App.MODEL_volti.model.children[0].geometry;
		self._manoObj = App.MODEL_mano.model.children[0].geometry;
		self._createParticles();
	}

	_createParticles(){
		let self = this;

		self._object0 = new MorphingLoader({ shape: 'volti', customShape: self._voltiObj });
		self._object1 = new MorphingLoader({ shape: 'mano', customShape: self._manoObj });

		self._bufferGeometry = new THREE.BufferGeometry();
		self._bufferGeometry.setAttribute( 'position', new THREE.BufferAttribute( self._object0._object, 3 ) );
		self._bufferGeometry.setAttribute( 'morph1', new THREE.BufferAttribute( self._object1._object, 3 ) );

			self._bufferGeometry.setAttribute( 'oscillazioneData', new THREE.BufferAttribute( self._object0._oscillazione, 3 ) );

	    self._mesh = new THREE.Points( self._bufferGeometry, self._shaderMaterial );

		self._mesh.scale.x = 0.1;
		self._mesh.scale.y = 0.1;
		self._mesh.scale.z = 0.1;

	    self._scene.add(self._mesh);
	}





	_render(){
		let self = this;
		if(self._renderable) self._animate();
	}
	_animate(){
		let self = this;

		self._time += 0.005;
		self._shaderMaterial.uniforms.oscillazione.value = self._time;

		window.innerWidth <= 991 ? self._shaderMaterial.uniforms.size.value = 3.0 : self._shaderMaterial.uniforms.size.value = 2.0;


		self._mesh.position.x = self._mesh.position.x + (self._newMeshPos.x - self._mesh.position.x) * 0.5 * 0.08;
		self._mesh.position.y = self._mesh.position.y + (self._newMeshPos.y - self._mesh.position.y) * 0.5 * 0.10;
		self._mesh.position.z = self._mesh.position.z + (self._newMeshPos.z - self._mesh.position.z) * 0.5 * 0.12;

		self._mesh.rotation.x = self._mesh.rotation.x + (self._newMeshRotation.x - self._mesh.rotation.x) * 0.5 * 0.12;
		self._mesh.rotation.y = self._mesh.rotation.y + (self._newMeshRotation.y - self._mesh.rotation.y) * 0.5 * 0.06;
		self._mesh.rotation.z = self._mesh.rotation.z + (self._newMeshRotation.z - self._mesh.rotation.z) * 0.5 * 0.08;
	}

}


export default Morphing1;


/**  @author Daniele */


import $ from 'jquery';
import { TweenMax } from 'gsap';

import Navigo from 'navigo';


class RouterMain{

    constructor(opt) {
        let self = this;

        self._firstLoad = true;
    }

    init(){
        let self = this;

        self._initRouter();
    }

    _initRouter(){
        let self = this;

        var root    = App.BASEURL;
        var useHash = false;
        var router  = new Navigo(root, useHash);

        router.on({
            ':lang/:page/': function (params) {
                self._askToUpdateAnimation(params.lang, params.page);
                if(!self._firstLoad) self._askToChangePage(params.lang, params.page);
                if(self._firstLoad) self._firstLoad = false;
            },
            ':lang': function (params) {
                self._askToUpdateAnimation(params.lang, 'home');
                if(!self._firstLoad) self._askToChangePage(params.lang, 'home');
                if(self._firstLoad) self._firstLoad = false;
            }
        }).resolve();

        $('body').on('click', 'a', function(){
            if(!$(this).hasClass('no-ajax')){
                let ajaxHref = $(this).data('ajax-href');
                router.navigate(ajaxHref);
                return false;
            }
        });


    }

    _askToUpdateAnimation(p_lang, p_page){
        let self = this;

        switch(p_page){
            case 'home':
                if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'TUNNEL_1', null);
                break;
            case 'flow':
                if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'TUNNEL_1', null);
                break;
            case 'knowledge':
                if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'TERRAIN', null);
                break;
            case 'discovery':
                if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'MORPH-0', null);
                break;
            case 'we-are-we-do':
                if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'MORPH-1', null);
                break;
            default:
                if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PAUSE', null, null);
                break;
        }

    }
    _askToChangePage(p_lang, p_page){
        let self = this;

        App.PAGE_VIEW.changePage(p_lang, p_page);
    }

}


export default RouterMain;

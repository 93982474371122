
/**  @author Daniele */


import $ from 'jquery';
import { TweenMax } from 'gsap';

import People from './compositions/people/people';
import Heart from './compositions/heart/heart';
import Polmone from './compositions/polmone/polmone';
import Tunnel from './compositions/tunnel/tunnel';
import Video from './compositions/video/video';
import Morphing0 from './compositions/morphing/morphing0';
import Morphing1 from './compositions/morphing/morphing1';
import Morphing2 from './compositions/morphing/morphing2';
import Terrain from './compositions/terrain/terrain';


const THREE = require('three');
window.THREE = THREE;
import { FBXLoader } from'three/examples/jsm/loaders/FBXLoader.js';


class AnimationView{

    constructor(opt) {
        let self = this;

        self._parent 			= opt.parent;
        self._play 				= true;
        self._time				= 0;
        self._compositions      = new Array();

        self._startCamera		= { x: 0, y: 0, z: 100 };


        // MODELLI
        self._isLoaded      = false;
        self._isReady       = false;
        App.MODEL_male      = null;
        App.MODEL_female    = null;
        App.MODEL_heart     = null;
        App.MODEL_polmone   = null;
        App.MODEL_canoa     = null;
        App.MODEL_cammelli  = null;
        App.MODEL_flamingo  = null;
        App.MODEL_volti     = null;
        App.MODEL_mano      = null;
        App.MODEL_airbus    = null;
        App.MODEL_leonardo  = null;


        // CAMERA
        self._camera 			= new THREE.PerspectiveCamera(45, self._parent.clientWidth / self._parent.clientHeight, 1, 1000);
        self._camera.position.x	= self._startCamera.x;
        self._camera.position.y	= self._startCamera.y;
        self._camera.position.z	= self._startCamera.z;


        // SCENE
        self._scene 	= new THREE.Scene();
        self._camera.lookAt(self._scene.position);


        // AXES
        var axesHelper = new THREE.AxesHelper(50);
        //self._scene.add(axesHelper);



        // RENDERER
        self._renderer = new THREE.WebGLRenderer({ antialias: false, alpha: true });
        self._renderer.setPixelRatio(window.devicePixelRatio);
        self._renderer.setSize(self._parent.clientWidth, self._parent.clientHeight);

        window.addEventListener( 'resize', onWindowResize, false );
        function onWindowResize(){
            self._camera.aspect = self._parent.clientWidth / self._parent.clientHeight;
            self._camera.updateProjectionMatrix();
            self._renderer.setSize(self._parent.clientWidth, self._parent.clientHeight);
        }

        self._parent.appendChild(self._renderer.domElement);

        self._optimizePerformance()

        self._initLoader();
    }
    init(){
        let self = this;

        self._init();
    }
    play(p_name, p_data){
        let self = this;

        switch(p_name){
            case 'HEART':
                self._playHeart();
                break;
            case 'POLMONE':
                self._playPolmone();
                break;
            case 'PEOPLE':
                self._playPeople();
                break;
            case 'TUNNEL_1':
                self._playTunnel();
                break;
            case 'VIDEO':
                self._playVideo(p_data);
                break;
            case 'SAMPLE':
                self._playSample();
                break;
            case 'MORPH-0':
                self._playMorph0();
                break;
            case 'MORPH-1':
                self._playMorph1();
                break;
            case 'MORPH-2':
                self._playMorph2();
                break;
            case 'TERRAIN':
                self._playTerrain();
                break;
        }
    }
    pause(p_name, p_data){
        let self = this;

        switch(p_name){
            case 'HEART':
                self._pauseHeart();
                break;
            case 'POLMONE':
                self._pausePolmone();
                break;
            case 'PEOPLE':
                self._pausePeople();
                break;
            case 'TUNNEL_1':
                self._pauseTunnel();
                break;
            case 'VIDEO':
                self._pauseVideo();
                break;
            case 'SAMPLE':
                self._pauseSample();
                break;
            case 'MORPH-0':
                self._pauseMorph0();
                break;
            case 'MORPH-1':
                self._pauseMorph1();
                break;
            case 'MORPH-2':
                self._pauseMorph2();
                break;
            case 'TERRAIN':
                self._pauseTerrain();
                break;
        }
    }
    change(p_name, p_data){
        let self = this;

        switch(p_name){
            case 'MORPH-0':
                self._changeMorph0(p_data);
                break;
            case 'MORPH-1':
                self._changeMorph1(p_data);
                break;
            case 'MORPH-2':
                self._changeMorph2(p_data);
                break;
            case 'HEART':
                self._changeHeart(p_data);
                break;
        }
    }


    _optimizePerformance(){
        let self = this;

        self._fpsCheck();
    }
    _fpsCheck(){
        let self = this;

        const times = [];
        var fps;
        self._fps = null;

        function refreshLoop() {
            window.requestAnimationFrame(() => {
                const now = performance.now();
                while (times.length > 0 && times[0] <= now - 1000) times.shift();
                times.push(now);
                fps = times.length;
                refreshLoop();
          });
        }

        setInterval(function(){ self._fps = fps; }, 1000);
        refreshLoop();
    }


    _initLoader(){
        let self = this;

        var loaderFBX = new FBXLoader();


        loaderFBX.load(App.BASEURL + '/assets/js/models/heart.fbx', function(object) {
            App.MODEL_heart = { model: object, type: 'fbx' };
        });
        loaderFBX.load(App.BASEURL + '/assets/js/models/polmone.fbx', function(object) {
            App.MODEL_polmone = { model: object, type: 'fbx' };
        });
        loaderFBX.load(App.BASEURL + '/assets/js/models/canoa.fbx', function(object) {
            App.MODEL_canoa = { model: object, type: 'fbx' };
        });
        loaderFBX.load(App.BASEURL + '/assets/js/models/cammelli.fbx', function(object) {
            App.MODEL_cammelli = { model: object, type: 'fbx' };
        });
        loaderFBX.load(App.BASEURL + '/assets/js/models/flamingo.fbx', function(object) {
            App.MODEL_flamingo = { model: object, type: 'fbx' };
        });
        loaderFBX.load(App.BASEURL + '/assets/js/models/volti.fbx', function(object) {
            App.MODEL_volti = { model: object, type: 'fbx' };
        });
        loaderFBX.load(App.BASEURL + '/assets/js/models/mano.fbx', function(object) {
            App.MODEL_mano = { model: object, type: 'fbx' };
        });
        loaderFBX.load(App.BASEURL + '/assets/js/models/airbus.fbx', function(object) {
            App.MODEL_airbus = { model: object, type: 'fbx' };
        });
        loaderFBX.load(App.BASEURL + '/assets/js/models/leonardo.fbx', function(object) {
            App.MODEL_leonardo = { model: object, type: 'fbx' };
        });


        var checkLoad = setInterval(function(){
    		if( App.MODEL_heart &&
                App.MODEL_polmone &&
                App.MODEL_canoa &&
                App.MODEL_cammelli &&
                App.MODEL_flamingo &&
                App.MODEL_volti &&
                App.MODEL_mano &&
                App.MODEL_airbus &&
                App.MODEL_leonardo
            ){
    			self._isLoaded = true;
    			clearInterval(checkLoad);
    		}
    	}, 50);
    }



    _init(){
        let self = this;

        self._initMorphing0();
        self._initMorphing1();
        self._initMorphing2();
        self._initHeart();
        self._initPolmone();
        self._initTunnel();
        self._initVideo();
        self._initTerrain();

        self._render();
    }

    // _addLight
    _addLight(){
		let self = this;

		var ambientLight = new THREE.AmbientLight(0x404040); // soft white light
		self._scene.add(ambientLight);
	}


    // _initSample
    _initSample(){
        let self = this;

        self._compositionSample = new Sample({
            scene: self._scene,
            camera: self._camera,
            renderer: self._renderer,
        });
        self._compositions.push(self._compositionSample);
    }
    _playSample(){
        let self = this;
        self._compositionSample.play();
    }
    _pauseSample(){
        let self = this;
        self._compositionSample.pause();
    }


    // _initTunnel
    _initTunnel(){
        let self = this;

        self._compositionTunnel = new Tunnel({
            scene: self._scene,
            camera: self._camera,
            renderer: self._renderer,
        });
        self._compositions.push(self._compositionTunnel);
    }
    _playTunnel(){
        let self = this;
        self._compositionTunnel.play();
    }
    _pauseTunnel(){
        let self = this;
        self._compositionTunnel.pause();
    }


    // _initTunnel
    _initTerrain(){
        let self = this;

        self._compositionTerrain = new Terrain({
            scene: self._scene,
            camera: self._camera,
            renderer: self._renderer,
        });
        self._compositions.push(self._compositionTerrain);
    }
    _playTerrain(){
        let self = this;
        self._compositionTerrain.play();
    }
    _pauseTerrain(){
        let self = this;
        self._compositionTerrain.pause();
    }



    // _initVideo
    _initVideo(){
        let self = this;

        self._compositionVideo = new Video({
            scene: self._scene,
            camera: self._camera,
            renderer: self._renderer,
        });
        self._compositions.push(self._compositionVideo);
    }
    _playVideo(p_id){
        let self = this;
        self._compositionVideo.play(p_id);
    }
    _pauseVideo(){
        let self = this;
        self._compositionVideo.pause();
    }




    // _initHeart
    _initHeart(){
        let self = this;

        self._compositionHeart = new Heart({
            scene: self._scene,
            camera: self._camera,
            renderer: self._renderer,
        });
        self._compositions.push(self._compositionHeart);
    }
    _playHeart(){
        let self = this;
        self._compositionHeart.play();
    }
    _pauseHeart(){
        let self = this;
        self._compositionHeart.pause();
    }
    _changeHeart(p_data){
        let self = this;
        self._compositionHeart.change(p_data);
    }


    // _initPolmone
    _initPolmone(){
        let self = this;

        self._compositionPolmone = new Polmone({
            scene: self._scene,
            camera: self._camera,
            renderer: self._renderer,
        });
        self._compositions.push(self._compositionPolmone);
    }
    _playPolmone(){
        let self = this;
        self._compositionPolmone.play();
    }
    _pausePolmone(){
        let self = this;
        self._compositionPolmone.pause();
    }


    // _initPeople
    _initPeople(){
        let self = this;

        self._compositionPeople = new People({
            scene: self._scene,
            camera: self._camera,
            renderer: self._renderer,
        });
        self._compositions.push(self._compositionPeople);
    }
    _playPeople(){
        let self = this;
        self._compositionPeople.play();
    }
    _pausePeople(){
        let self = this;
        self._compositionPeople.pause();
    }



    // _initMorphing
    _initMorphing0(){
        let self = this;

        self._compositionMorphing0 = new Morphing0({
            scene: self._scene,
            camera: self._camera,
            renderer: self._renderer,
        });
        self._compositions.push(self._compositionMorphing0);
    }
    _playMorph0(){
        let self = this;
        self._compositionMorphing0.play();
    }
    _pauseMorph0(){
        let self = this;
        self._compositionMorphing0.pause();
    }
    _changeMorph0(p_data){
        let self = this;
        self._compositionMorphing0.changeModel(p_data);
    }



    // _initMorphing
    _initMorphing1(){
        let self = this;

        self._compositionMorphing1 = new Morphing1({
            scene: self._scene,
            camera: self._camera,
            renderer: self._renderer,
        });
        self._compositions.push(self._compositionMorphing1);
    }
    _playMorph1(){
        let self = this;
        self._compositionMorphing1.play();
    }
    _pauseMorph1(){
        let self = this;
        self._compositionMorphing1.pause();
    }
    _changeMorph1(p_data){
        let self = this;
        self._compositionMorphing1.changeModel(p_data);
    }



    // _initMorphing
    _initMorphing2(){
        let self = this;

        self._compositionMorphing2 = new Morphing2({
            scene: self._scene,
            camera: self._camera,
            renderer: self._renderer,
        });
        self._compositions.push(self._compositionMorphing2);
    }
    _playMorph2(){
        let self = this;
        self._compositionMorphing2.play();
    }
    _pauseMorph2(){
        let self = this;
        self._compositionMorphing2.pause();
    }
    _changeMorph2(p_data){
        let self = this;
        self._compositionMorphing2.changeModel(p_data);
    }




    _render(){
		let self = this;

		if(self._play){
			self._animate();
			window.requestAnimationFrame(function(){ self._render(); });
		} else {
			window.requestAnimationFrame(function(){ self._render(); });
		}

	}
	_animate(){
		let self = this;

        for(let i = 0; i < self._compositions.length; i++) self._compositions[i].render();

        self._renderer.render(self._scene, self._camera);
	}



}


export default AnimationView;

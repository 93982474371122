/* global App */
import '@babel/polyfill';
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin';
import { TweenMax, visibility } from 'gsap';
const plugins = [visibility];


import Router from './app/general/router';
import Header from './app/general/header';
import Page from './app/general/page';


import AnimationView from './app/animation/animation-view';
import AnimationManager from './app/animation/animation-manager';


import './app/cookie-policy-banner/jquery.cookies';
import './app/cookie-policy-banner/jquery.cookiepolicybanner';


window.onload = function(){
    var AudioContext = window.AudioContext || window.webkitAudioContext || false;
    App.AUDIO_CONTEXT =  new AudioContext;
}

document.addEventListener('contextmenu', event => event.preventDefault());

$(document).ready(() => {


	/*/ --- COOKIES --- /*/
    /*
    $('body').cookiePolicyBanner({
        bannerURL	: App.BASEURL +'/assets/js/cookie-policy-banner/template_' + App.LANG + '.php',
        policyURL	: App.BASEURL +'/assets/js/cookie-policy-banner/modello_modale_' + App.LANG + '.html',
        isModal		: true,
        auto		: true,
        event		: 'click',
        onEstesaOpen: function($modal) {
            $('.cookie-policy-page-container').mCustomScrollbar({ alwaysShowScrollbar: 1 });
            $('.cookie-policy-banner').addClass('open-popup');
            TweenMax.to($modal, .5, { opacity: 1 });
        },
        onEstesaClose: function($modal) {
            $('.cookie-policy-banner').removeClass('open-popup');
            TweenMax.to($modal, .5, { autoAlpha: 0, onComplete: function() { $modal.remove(); } });
        }
    });
    */

    $('.full-screen').on('click', function(){
        var elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        }
    })


    function manageAudioContext(){

        setInterval(function(){
            //if(App.AUDIO_CONTEXT) console.log(App.AUDIO_CONTEXT.state);
        }, 2000);

        $(window).on('scroll', function(){
            if(App.AUDIO_CONTEXT) App.AUDIO_CONTEXT.resume();
        });
        $('body').one('click', '*', function(){
            if(App.AUDIO_CONTEXT) App.AUDIO_CONTEXT.resume();
        });
    }
    manageAudioContext();

	function start(){

		let animationView = new AnimationView({ parent: document.getElementById('main-canvas') });

		var checkLoad = setInterval(function(){
			if(animationView._isLoaded){

                // PRELOADER
                TweenMax.to($('#preloader .preloader__text'), .3, { opacity: 0, ease: 'none'});
                TweenMax.to($('#preloader'), .5, {
                    delay: 1,
                    opacity: 0,
                    ease: 'none',
                    pointerEvents: 'none',
                    onComplete: function(){
                        $('#preloader').remove();
                    }
                });

				// ANIMAZIONE
				animationView.init();
                App.INVIEW_ANIMATION = true;
				App.ANIMATION_MANAGER = new AnimationManager({ animationView: animationView });
				App.ANIMATION_MANAGER.init();

				// BASE
				App.ROUTER      = new Router();
				App.HEADER      = new Header();
				App.PAGE_VIEW   = new Page();
				App.ROUTER.init();
				App.HEADER.init();
				App.PAGE_VIEW.init();

				clearInterval(checkLoad);
			}
		}, 50);


	}


	start();

	window.onpageshow = function(event) {
	    if (event.persisted) { start(); }
	}


});


/**  @author Daniele */

import $ from 'jquery';
import { TweenMax } from 'gsap';
import Composition from '../composition';

const THREE = require('three');
window.THREE = THREE;
import { Curves } from 'three/examples/jsm/curves/CurveExtras.js';


class Tunnel extends Composition{

	_doConstructor(){
		let self = this;

		self._name			= 'TUNNEL';
		self._time 			= 0;
		self._speed			= 0.0004;
		self._tunnelCurve 	= null;

		self._shaderMaterial = new THREE.ShaderMaterial({
			depthWrite: false,
			transparent: true,
			vertexColors: THREE.VertexColors,
			uniforms: { size: { value: 4 }, opacity: { value: 0.0 }, },
			vertexShader: document.getElementById( 'vertexShaderBase' ).textContent,
			fragmentShader: document.getElementById( 'fragmentShaderBase' ).textContent,
		});

		self._generateTunnel();
	}

	_doPlay(){
		let self = this;
		TweenMax.to(self._shaderMaterial.uniforms.opacity, 0.3, { value: 1, ease: 'none' });
	}
	_doPause(){
		let self = this;
		TweenMax.to(self._shaderMaterial.uniforms.opacity, 0.3, { value: 0, ease: 'none' });
	}
	_doRender(){
		let self = this;
		self._render();
	}



	_generateTunnel(){
		let self = this;

		let pointsArray = new Array();

		self._tunnelCurve = new Curves.TorusKnot(10);
		let tunnelGeometry = new THREE.TubeGeometry(self._tunnelCurve, 2500, 2, 80, false);
		self._generateTunnelParticles(tunnelGeometry);

	}
	_generateTunnelParticles(p_tunnelGeometry){
		let self = this;

		let particlesGeometry = p_tunnelGeometry;

		for (let i = 0; i < particlesGeometry.vertices.length; i++) {
			particlesGeometry.vertices[i] = {
				x: particlesGeometry.vertices[i].x + Math.random() * 0.4 - 0.2,
				y: particlesGeometry.vertices[i].y + Math.random() * 0.4 - 0.2,
				z: particlesGeometry.vertices[i].z + Math.random() * 0.4 - 0.2,
			};
			particlesGeometry.colors.push(new THREE.Color(0, 3, 3));
		}

		self._particles = new THREE.Points(particlesGeometry, self._shaderMaterial);
		self._scene.add(self._particles);
	}




	_doUpdateCamera(){
		let self = this;

		if(!self._camerable) return;

		self._time += self._speed;
		let position = self._tunnelCurve.getPointAt(self._time);

		if(position.x){
			self._newCameraPosition.x = position.x;
			self._newCameraPosition.y = position.y;
			self._newCameraPosition.z = position.z;
			let positionLook = self._time <= 0.999 ? self._tunnelCurve.getPointAt(self._time + self._speed) : self._tunnelCurve.getPointAt(0.00);
			self._newCameraLookAt = positionLook;
		} else {
			self._time = 0;
		}
	}






	_render(){
		let self = this;
		if(self._renderable) self._animate();
	}
	_animate(){
		let self = this;
	}

}


export default Tunnel;

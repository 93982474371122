
/**  @author Daniele */


import $ from 'jquery';
import { TweenMax } from 'gsap';
import 'jquery.scrollto';
import 'jquery.easing';
import '../components/jQuery-inView.min';
import 'jquery-appear-original';

import Hammer from 'hammerjs';
import Swiper from 'swiper';

import BloccoVideo from '../bloccovideo/bloccovideo';

class PageMain{

    constructor(opt) {
        let self = this;

        self.$el = $('#page');
        self._$triggers = $('.trigger-animation');
        self._intervalGallery = null;

        if(opt && opt.el) self.el   = opt.el;
        if(opt && opt.el) self.$el  = $(opt.el);
    }

    init(){
        let self = this;

        self._setIntroVisible();
        self._checkInView();
        self._checkAppearAnimations();

        // bind to window scroll event
        $(window).scroll(function() { if(!$('#header').hasClass('open')) self._checkInView(); });

        self._startPage();
    }
    _checkInView() {
        let self = this;

        $('.inView').each(function(){
            if ($(this).inView('both')) {
                $(this).addClass('active');
            }
        });
    }
    _checkAppearAnimations(){
        let self = this;

        $.appear('.trigger-animation');

        // Devo fare cosi per considerare solo l'ultimo elemento visibile

        /*$(window).scroll(function(){
            let composition = null;
            let action = null;
            let data = null;
            self._$triggers.each(function(){
                if($(this).is(':appeared')){
                    composition = $(this).attr('data-animation-composition');
                    action = $(this).attr('data-animation-type');
                    data = $(this).attr('data-animation-data');
                }
            });
            console.log(App.ANIMATION_MANAGER._activeComposition);
            if(composition) if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update(action, composition, data);
        });*/

        setInterval(function(){
            let composition = null;
            let action = null;
            let data = null;
            self._$triggers.each(function(){
                if($(this).is(':appeared')){
                    composition = $(this).attr('data-animation-composition');
                    action = $(this).attr('data-animation-type');
                    data = $(this).attr('data-animation-data');
                }
            });
            if(App.INVIEW_ANIMATION) if(composition) if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update(action, composition, data);
        }, 100);
    }


    _startPage(){
        let self = this;

        self._setIntroVisible();
        self._initSlider();
        self._holdBtnFancy();
        self._initBloccoVideo();

        self._$triggers = $('.trigger-animation');
    }

    _setIntroVisible() {
        let self = this;

		$('.intro__container-title').addClass('active');
		$('.intro__testo-big').addClass('active');
		$('.intro__testo-small').addClass('active');
	}
	_setIntroInvisible() {
        let self = this;

		$('.intro__container-title').removeClass('active');
		$('.intro__testo-big').removeClass('active');
		$('.intro__testo-small').removeClass('active');
	}


    _initSlider(){
        let self = this;

        var swiper = new Swiper('.page__slider', { freeMode: true, spaceBetween: 0, slidesPerView: 'auto' });

        // AUTOGALLERY
        /*var $imgs = $('.blocco-immagini--desktop img');
        var imgsLength = $imgs.length;
        if(imgsLength > 0){
            var random = -1;
            self._intervalGallery = setInterval(function(){
                random = self._findRandom(random, imgsLength);
                var $img = $imgs.eq(random);
                TweenMax.to($imgs, .3, { 'opacity': 0, ease: 'none'  });
                TweenMax.to($img, .3, { 'opacity': 1, ease: 'none' });
            }, 3000);
        }*/
    }
    _findRandom(p_number, p_range){
        let self = this;

        var newRandom = Math.floor(Math.random() * p_range);
        if(newRandom == p_number){
            self._findRandom(p_number, p_range);
            //return newRandom;
        } else {
            return newRandom;
        }

    }


    _holdBtnFancy(){
        let self = this;

		var myPage 			= document.getElementById($('.page').attr('id'));
		var _myHammerPage	= new Hammer(myPage);

		$('.general-cta').each(function(){
			var isPress 		= false;
			var $allBtn			= $('.general-cta');
			var $btn			= $(this);
			$btn.on('click', function(){
                if($(this).hasClass('pulse-heart')){ /*$('.testo-home').addClass('active');*/ }
				if(isPress) return false;
				setTimeout(function(){ isPress = true; }, 1000);
			});

			var btnHammer	= new Hammer($btn[0]);

            var timePress       = 0;
            var intervalTime    = null;

			/*btnHammer.on('press panstart touchstart', function(){

                App.AUDIO_CONTEXT.resume();

				isPress = true;
				$btn.find('.general-cta-line').addClass('press');

                if($btn.hasClass('pulse-heart')){
                    $('.testo-home').addClass('active');
                }

                // ANIMAZIONI
                if($btn.hasClass('show-polmone'))           if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'POLMONE', null);
                if($btn.hasClass('pulse-heart'))            if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('CHANGE', 'HEART', 'START-PULSE');

                if($btn.hasClass('show-video-home'))        if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'VIDEO', 'home');
                if($btn.hasClass('show-video-flow'))        if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'VIDEO', 'flow');
                if($btn.hasClass('show-video-knowledge'))   if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'VIDEO', 'knowledge');
                if($btn.hasClass('show-video-discovery'))   if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'VIDEO', 'discovery');

                intervalTime = setInterval(function(){
                    timePress += 10;
                }, 10);

			});

			btnHammer.on('pressup panend touchend', function(){
				if(!isPress) return false;
				isPress = false;

                $btn.find('.general-cta-line').removeClass('press');

                if($btn.hasClass('pulse-heart'))  $('.testo-home').removeClass('active');

                if(intervalTime) clearInterval(intervalTime);


                // ANIMAZIONI
                if($btn.hasClass('pulse-heart'))    if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('CHANGE', 'HEART', 'STOP-PULSE');

                var timeAfterClose = 0;
                if(timePress < 500) timeAfterClose = 500;
                timePress = 0;

                setTimeout(function(){
                    if($btn.hasClass('show-polmone'))           if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'TUNNEL_1', null);
                    if($btn.hasClass('show-video-home'))        if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'TUNNEL_1', null);
                    if($btn.hasClass('show-video-flow'))        if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'TUNNEL_1', null);
                    if($btn.hasClass('show-video-knowledge'))   if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'TERRAIN', null);
                    if($btn.hasClass('show-video-discovery'))   if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'MORPH-0', 0);
                }, timeAfterClose);


			});*/

            $btn.on('mousedown touchstart', function(){

                App.AUDIO_CONTEXT.resume();
                App.INVIEW_ANIMATION = false;

				isPress = true;
				$btn.find('.general-cta-line').addClass('press');

                if($btn.hasClass('pulse-heart')) $('.testo-home').addClass('active');

                // ANIMAZIONI
                if($btn.hasClass('show-polmone'))           if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'POLMONE', null);
                if($btn.hasClass('pulse-heart'))            if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('CHANGE', 'HEART', 'START-PULSE');

                if($btn.hasClass('show-video-home'))        if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'VIDEO', 'home');
                if($btn.hasClass('show-video-flow'))        if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'VIDEO', 'flow');
                if($btn.hasClass('show-video-knowledge'))   if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'VIDEO', 'knowledge');
                if($btn.hasClass('show-video-discovery'))   if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'VIDEO', 'discovery');

                intervalTime = setInterval(function(){ timePress += 10; }, 10);

			});


			$btn.on('mouseup touchend mouseleave', function(){

				if(!isPress) return false;
                isPress = false;

                App.INVIEW_ANIMATION = true;


                $btn.find('.general-cta-line').removeClass('press');
                if($btn.hasClass('pulse-heart'))  $('.testo-home').removeClass('active');

                if(intervalTime) clearInterval(intervalTime);


                // ANIMAZIONI
                if($btn.hasClass('pulse-heart'))    if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('CHANGE', 'HEART', 'STOP-PULSE');

                var timeAfterClose = 0;
                if(timePress < 500) timeAfterClose = 500;
                timePress = 0;

                setTimeout(function(){
                    if($btn.hasClass('show-polmone'))           if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'TUNNEL_1', null);
                    if($btn.hasClass('show-video-home'))        if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'TUNNEL_1', null);
                    if($btn.hasClass('show-video-flow'))        if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'TUNNEL_1', null);
                    if($btn.hasClass('show-video-knowledge'))   if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'TERRAIN', null);
                    if($btn.hasClass('show-video-discovery'))   if(App.ANIMATION_MANAGER) App.ANIMATION_MANAGER.update('PLAY', 'MORPH-0', 0);
                }, timeAfterClose);


			});

		});
	}


    _initBloccoVideo(){
        let self = this;

        $('.blocco-video').each(function(){
            let video = new BloccoVideo({ el: $(this)[0] });
            video.init();
        });
    }



    /* CAMBIO PAGINA */
    changePage(p_lang, p_page){
        let self = this;

        App.INVIEW_ANIMATION = false;
        if(self._intervalGallery) clearInterval(self._intervalGallery);

        self._changePage(p_lang, p_page);
    }
    _changePage(p_lang, p_page){
        let self = this;

        TweenMax.to(self.$el.find('.page__content'), .5, {
            opacity: 0,
            ease: 'none',
            onStart: function(){  },
            onComplete: function(){
                $.ajax({
                    type: 'GET',
                    url: App.BASEURL + '/assets/php/pages/main.php?page=' + p_page +'&lang=' + p_lang,
                    success: function(data){
                        $.scrollTo(0, 0, { easing:'easeInOutCirc' });
                        self.$el.find('.page__content').empty();
                        self.$el.find('.page__content').html(data);
                        TweenMax.to(self.$el.find('.page__content'), .5, { opacity: 1, ease: 'none' });
                        self._startPage();
                        App.INVIEW_ANIMATION = true;
                    }
                });
            }
        });

    }

}


export default PageMain;

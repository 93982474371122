
/**  @author Daniele */


import $ from 'jquery';
import { TweenMax } from 'gsap';

class HeaderMain{

    constructor(opt) {
        let self = this;

        self.$el = $('body');
        self._prevScroll = 0;
        self._navOpen = false;
        self._navbarStatus = 'visible';

        if(opt && opt.el) self.el   = opt.el;
        if(opt && opt.el) self.$el  = $(opt.el);
    }

    init(){
        let self = this;

        self._onOpenNav();
        self._onCloseNav();
        self._onSroll();

        $('.header__nav-link').on('click', function(){ self._closeNav(); });
    }

    _onOpenNav(){
        let self = this;

        $('.header__btn-nav--open').on('click', function(){ self._openNav(); });
    }
    _onCloseNav(){
        let self = this;

        $('.header__btn-nav--close').on('click', function(){ self._closeNav(); });
    }

    _openNav(){
        let self = this;

        self._navOpen = true;
        self._setIntroInvisible();
        $('.inView.active').addClass('open-header');
        $('#header').addClass('open');

        TweenMax.staggerTo($('.header__nav-link--white'), .8, {
            y: 0,
            delay: .5,
            rotate: 0,
            pointerEvents: 'auto',
            opacity: .7,
            ease: 'circ.out'
        }, .1);
        TweenMax.staggerTo($('.header__nav-link, .header__bottom-info').not('.header__nav-link--white'), .8, {
            y: 0,
            delay: .5,
            rotate: 0,
            opacity: 1,
            pointerEvents: 'auto',
            ease: 'circ.out'
        }, .1);

    }
    _closeNav(){
        let self = this;

        self._navOpen = false;
        $('#header').removeClass('open');
        TweenMax.staggerTo($('.header__nav-link, .header__bottom-info'), .5, {
            y: '6vw',
            opacity: 0,
            rotate: '3deg',
            ease: 'circ.in',
            pointerEvents: 'none',
        }, -.1);

        setTimeout(function(){
            self._setIntroVisible();
            $('.inView.open-header').removeClass('open-header');
        }, 550);

        self._checkInView();
    }


    _checkInView() {
        let self = this;

        $('.inView').each(function(){
            if ($(this).inView('both')) {
              $(this).addClass('active');
            }
        });
    }

    _setIntroVisible() {
        let self = this;

		$('.intro__container-title').addClass('active');
		$('.intro__testo-big').addClass('active');
		$('.intro__testo-small').addClass('active');
	}

	_setIntroInvisible() {
        let self = this;

		$('.intro__container-title').removeClass('active');
		$('.intro__testo-big').removeClass('active');
		$('.intro__testo-small').removeClass('active');
	}

    // _onScroll
    _onSroll(){
        let self = this;

        $(window).on('scroll', function(){

            if(!self._navOpen){
                var scrollTop = $(window).scrollTop();
                if(scrollTop > self._prevScroll){
                    if(self._navbarStatus == 'visible' && scrollTop > 300){
                        $('#header').addClass('invisible');
                        self._navbarStatus = 'invisible'
                    }
                } else {
                    if(self._navbarStatus == 'invisible' ){
                        $('#header').removeClass('invisible');
                        self._navbarStatus = 'visible'
                    }
                }
            }

            self._prevScroll = scrollTop;
        });
    }

}


export default HeaderMain;


/**  @author Daniele */


import $ from 'jquery';
import { TweenMax } from 'gsap';





class AnimationManager{

    constructor(opt) {
        let self = this;

        self._animationView = opt.animationView;
        self._activeComposition = null;
        self._changeInProgress  = false;
    }
	update(p_type, p_name, p_data = null){
		let self = this;

		self._update(p_type, p_name, p_data);
	}
    init(){
        let self = this;

		self._init();
    }


	_init(){
		let self = this;

	}

	_update(p_type, p_name, p_data){
		let self = this;

		switch (p_type){

			case 'PAUSE':
				self._pauseComposition(self._activeComposition, p_data);
				break;
			case 'PLAY':
				self._playComposition(p_name, p_data);
				break;
			case 'CHANGE':
				self._changeComposition(p_name, p_data);
				break;
		}
	}
	_pauseComposition(p_name, p_data){
		let self = this;

        self._animationView.pause(p_name, p_data);
        self._activeComposition = null;
	}
	_playComposition(p_name, p_data){
		let self = this;

        if(self._changeInProgress) return;
        if(self._activeComposition == p_name) return;

        self._changeInProgress = true;
        if(self._activeComposition == null){
            self._animationView.play(p_name, p_data);
            self._activeComposition = p_name;
            self._changeInProgress = false;
        } else {
            $('body').one('composition-paused', function(){
                self._animationView.play(p_name, p_data);
                self._activeComposition = p_name;
                self._changeInProgress = false;
            });
            self._animationView.pause(self._activeComposition, p_data);
        }
	}
	_changeComposition(p_name, p_data){
		let self = this;

        self._animationView.change(self._activeComposition, p_data);
	}

}


export default AnimationManager;

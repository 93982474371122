
/**  @author Daniele */


import $ from 'jquery';
import 'jquery-ui/ui/widgets/draggable';
import { TweenMax } from 'gsap';


class BloccoVideo{

    constructor(opt) {
        let self = this;

        self.$el = $('body');
        self._isPlayingInterval = null;
        self._canUpdateVideoTimer = true;
        self._firstBuffer = true;

        if(opt && opt.el) self.el   = opt.el;
        if(opt && opt.el) self.$el  = $(opt.el);
    }

    init(){
        let self = this;

        self._initVideoVimeo();
    }


	_initVideoVimeo(){
		var self = this;

        var iframe = self.$el.find('iframe')[0];
        self._player = new Vimeo.Player(iframe);


        self._player.ready().then(function() {

            self._player.play();
            self._player.pause();
            self._player.setCurrentTime(0);

            self._player.getDuration().then(function(duration){ self._videoDuration = duration; });

            self.$el.find('.blocco-video__cta').on('click', function(){
                if($(this).hasClass('pause')){
                    self._pauseVideo();
                } else {
                    self._playVideo();
                }
            });

            self._player.on('play', function(data) {
                self._status = 'playing';
                TweenMax.to(self.$el.find('.blocco-video__player-track'), .2, { opacity: 1, delay: 0.5 });
                if(self._isPlayingInterval) clearInterval(self._isPlayingInterval);
                self._isPlayingInterval = setInterval(function(){
                    var perc;
                    self._player.getCurrentTime().then(function(seconds){
                        perc = seconds / self._videoDuration * 100;
                        if(self._canUpdateVideoTimer) self._updateDraggableTimer(perc + '%');
                        if(self._canUpdateVideoTimer) self._updateTimerTrack(perc + '%');
                    });
                }, 5);
                self.$el.find('.blocco-video__spinner').css({ 'opacity': 0 });
                //console.log('play');
            });
            self._player.on('pause', function(data) {
                TweenMax.to(self.$el.find('.blocco-video__player-track'), .2, { opacity: 0 });
                self.$el.find('.blocco-video__spinner').css({ 'opacity': 0 });
                self._status = 'paused';
                //console.log('pause');
            });
            self._player.on('bufferstart', function(data) {
                self._isBuffering = true;
                self.$el.find('.blocco-video__spinner').css({ 'opacity': 1 });
                //console.log('bufferstart');
            });
            self._player.on('seeked', function(data) {
                if(self._isBuffering && self._status == 'playing')  self._player.play();
                self.$el.find('.blocco-video__spinner').css({ 'opacity': 0 });
                self._isBuffering = false;
                //console.log('seeked');
            });
            self._player.on('ended', function(data) {
                TweenMax.to(self.$el.find('.blocco-video__player-track'), .2, { opacity: 0 });
                self._status = 'ended';
                //console.log('ended');
            });

            self._onDragTimer();
        });

	}
    _playVideo(){
        let self = this;

        self._player.play();
        self._player.setVolume(1);
        TweenMax.to(self.$el.find('.blocco-video__pre'), .25, { opacity: 0, pointerEvents: 'none', ease: 'none' });
        TweenMax.to(self.$el.find('.blocco-video__video'), .25, { opacity: 1, pointerEvents: 'auto', ease: 'none' });
    }
    _pauseVideo(){
        let self = this;

        self._player.pause();
        TweenMax.to(self.$el.find('.blocco-video__pre'), .25, { opacity: 1, pointerEvents: 'auto', ease: 'none' });
        TweenMax.to(self.$el.find('.blocco-video__video'), .25, { opacity: 0, pointerEvents: 'none', ease: 'none' });
    }
    _onDragTimer(){
        var self = this;

        var newTime;

        self.$el.find(".blocco-video__timer").draggable({
            axis: 'x',
            refreshPositions: true,
            containment: $('.player-track'),
            start: function(e, ui){
                self._canUpdateVideoTimer = false;
                self.$el.find(".timer-offset").css({'display': 'block'});
            },
            drag: function(e, ui){
                var totalW 		= self.$el.find('.blocco-video__player-track').width();
                var actualXPos 	= ui.position.left;
                var percTime	= actualXPos / totalW;
                self._updateTimerTrack(percTime * 100 + '%');
                newTime 		= Math.round(self._videoDuration * percTime);
            },
            stop: function(e, ui){
                self.$el.find(".blocco-video__timer-offset").css({'display': 'none'});
                self._player.setCurrentTime(newTime);
                self._canUpdateVideoTimer = true;
            }
        });
    }
    _updateDraggableTimer(p_value){
        var self = this;
        self.$el.find('.blocco-video__timer').css({ 'left': p_value });
    }
    _updateTimerTrack(p_value){
        var self = this;
        self.$el.find('.blocco-video__timer-track').css({ 'width': p_value });
    }


}


export default BloccoVideo;
